.progress {
  width: 100%;
  background-color: #dadada;
  outline: none;
  cursor: pointer;

  .progress-inner {
    height: 100%;
  }
}
